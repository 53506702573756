import './App.css';
import Main from './components/main/Main';

function App() {
  return (
    <div className="App">

      <Main />


    </div>
  );
}

export default App;

