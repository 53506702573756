import React, { useEffect } from 'react';
import fullpage from 'fullpage.js';
import { useTranslation } from 'react-i18next';

//Styles//
import './Main.scss';
import './../styles/Home/Home.scss'
import './../styles/About/About.scss'
import './../styles/Contact/Contact.scss'
import './../styles/Intro/Intro.scss'
import './../styles/Info/Info.scss'
import './../styles/Footer/Footer.scss'

//Assets//
import firstImage from '../assets/firstImage.png'
import secondImage from '../assets/secondImage.png'
import thirdImage from '../assets/thirdImage.png'
import fourthImage from '../assets/fourthImage.png'

const languages = [{ key: 'en', flag: '🇺🇸' }, { key: 'hy', flag: '🇦🇲' }];

const Main = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    new fullpage("#fullPage", {
      autoScrolling: true,
      navigation: true,
      showActiveTooltip: false,
    });
  }, []);

  return (
    <div id='fullPage'>
      <section className="section home">
        <header>
          <div className='logo'></div>
          <div className='languages-container'>
            {languages.map((language) => (
              <button
                key={language.key}
                className='language'
                onClick={() => i18n.changeLanguage(language.key)}
              >
                {language.flag}
              </button>
            ))}
          </div>
        </header>
        <article className='homeContent'>
          <h1 className='homeTextHeader'>{t('section1.title')}</h1>
          <p className='homeTextParagraph'>{t('section1.description')}</p>
          <div className='linksSection'>
            <a href="https://t.me/BeloteClub" target="_blank" > <div className='telegram'></div> </a>
            <div className='socialMedia'>
              <a href="#" ><div className='appStore'> </div></a>
              <a href="#"> <div className='playMarket'></div></a>
            </div>
          </div>
        </article>
      </section>

      <section className="section about">
        <div className='aboutContent'>
          <div className='phoneVertical'> <img className='verticalPhoneImage' src={firstImage} alt="Belote" /></div>
          <article className='aboutTextContent'>
            <h2 className='aboutTextHeader'>{t('section2.title')}</h2>
            <p className='aboutTextParagraph'>{t('section2.description')}</p>
          </article>
        </div>
      </section>

      <section className="section contact">
        <div className='contactContent'>
          <div className='phoneHorizontal'> <img className='horizontalPhoneImage' src={secondImage} alt="Belote" /></div>
          <article className='contactTextContent'>
            <h2 className='contactTextHeader'>{t('section3.title')}</h2>
            <p className='contactTextParagraph'>{t('section3.description')}</p>
          </article>
        </div>
      </section>

      <section className="section intro">
        <div className='introContent'>
          <article className='introTextContent'>
            <h2 className='introTextHeader'>{t('section4.title')}</h2>
            <p className='introTextParagraph'>{t('section4.description')}</p>
          </article>
          <div className='phoneVertical'> <img className='verticalPhoneImage' src={thirdImage} alt="Belote" /></div>
        </div>
      </section>

      <section className="section info">
        <div className='infoContent'>
          <div className='phoneVertical'> <img className='verticalPhoneImage' src={fourthImage} alt="Belote" /></div>
          <article className='infoTextContent'>
            <h2 className='infoTextHeader'>{t('section5.title')}</h2>
            <p className='infoTextParagraph'>{t('section5.description')}</p>
          </article>
        </div>
      </section>

      <section className="section footer">
        <div className='footerContent'>
          <article className="footerTextContent">
            <h2 className='footerTextHeader'>{t('section6.title')}</h2>
            <p className='footerTextParagraph'>{t('section6.description')}</p>
          </article>
          <div className='linksSection'>
            <a href="https://t.me/BeloteClub" target="_blank"> <div className='telegram'></div> </a>
            <div className='socialMedia'>
              <a href="#"><div className='appStore'> </div></a>
              <a href="#"> <div className='playMarket'></div></a>
            </div>
          </div>
          <footer className='allRightsReservedSection'>{t('footer')}</footer>
        </div>
      </section>
    </div>
  );
};

export default Main;
